@font-face {
    font-family: 'OpenSans_Regular';
    src: url('../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans_Bold';
    src: url('../assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Exo_Thin';
    src: url('../assets/fonts/Exo/Exo-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Exo_Medium';
    src: url('../assets/fonts/Exo/Exo-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Exo_Bold';
    src: url('../assets/fonts/Exo/Exo-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Exo_Black';
    src: url('../assets/fonts/Exo/Exo-Black.ttf') format('truetype');
}