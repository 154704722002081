.about {
    position: relative;
    margin-bottom: 62px;
    padding: 52px 0 300px;
}

.bg_texture {
    position: absolute;
    top: -52px;
    right: 0;
    z-index: 0;
    width: 520px;
    height: 729px;
    background: url('../../theme/assets/images/texture_back_secondary.png') no-repeat;
    transform: rotate(180deg);
}

.container {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    max-width: var(--container-width);
    margin: 0 auto;
}

.photos {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 50%;
    margin-top: -50px;
}

.photos_first {
    position: relative;
    top: 80px;
    z-index: 2;
    width: 244px;
    height: 244px;
    border-radius: var(--card-bradius);
    background: var(--purpur0-bgcolor);
    transform: rotate(-9deg);

    &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 10px;
        z-index: 1;
        display: inline-block;
        width: 263px;
        height: 263px;
        padding: 2px;
        border-radius: var(--card-bradius);
        background: var(--pink45-bc);
        mask: linear-gradient(rgb(255, 255, 255) 0, rgb(255, 255, 255) 0) content-box, linear-gradient(rgb(255, 255, 255) 0, rgb(255, 255, 255) 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        box-sizing: border-box;
    }

    & > img {
        position: relative;
        top: -160px;
        left: -65px;
        z-index: 2;
        display: inline-block;
        width: 330px;
        height: 425px;
        transform: rotate(9deg);
    }

    .first_logo_icon {
        position: absolute;
        bottom: -30px;
        right: -160px;
        z-index: 2;
        display: inline-block;
        width: 163px;
        height: 60px;
        background: url('../../theme/assets/icons/main_logo.png') no-repeat;
        background-size: cover;
    }


}

.photos_second {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 238px;
    height: 238px;
    border-radius: var(--card-bradius);
    background: var(--pink-bgcolor);
    transform: rotate(4deg);

    & > img {
        position: relative;
        top: -127px;
        display: inline-block;
        width: 316px;
        height: 377px;
        transform: rotate(-4deg);
    }
}

.description {
    position: relative;
    top: 50px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 54%;
    margin-left: 90px;
}

.description_caption {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;

    & span:first-child {
        display: inline-block;
        font-family: Exo_Black, sans-serif;
        font-size: var(--main-title-fsize);
        line-height: 66px;
        color: var(--main-color);
    }

    & span:last-child {
        display: inline-block;
        font-family: Exo_Thin, sans-serif;
        font-size: var(--secondary-title-fsize);
        line-height: 93px;
        white-space: nowrap;
        color: var(--btn-color);
    }
}

.description_info_first,
.description_info_second {
    margin-bottom: 15px;
    font-size: var(--main-fsize);
    line-height: 25px;
}

.description_info_first {
    & span {
        display: block;
    }
}

.line_wrap {
    position: relative;
    bottom: -150px;
    z-index: 10;
}

.line_thin {
    position: absolute;
    top: 22px;
    width: 102%;
    height: 39px;
    margin-left: -8px;
    font-family: Exo_Medium, sans-serif;
    font-size: var(--main-fsize);
    line-height: 39px;
    transform: rotate(-4deg);
    background: linear-gradient(90deg, #FAB7FE 0%, #5B4CBE 100.87%);
    overflow: hidden;

    & > div {
        width: max-content;
    }

    span {
        white-space: nowrap;
    }

    & span:nth-child(2n + 1) {
        font-family: Exo_Bold, sans-serif;
    }
}

@media (min-width: 768px) and (max-width: 1333px) {
    .container {
        width: var(--container-width-ipad);
        margin: 0 var(--container-mg-ipad);
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .bg_texture {
        top: 120px;
    }

    .container {
        justify-content: space-between;
    }

    .photos {
        width: 45%;
        margin-top: -80px;
    }

    .photos_first {
        top: 183px;
        left: 110px;
    }

    .description_caption {
        & span:first-child {
            margin-left: 0;
        }
    }

    .description {
        width: 44%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .bg_texture {
        top: 120px;
    }

    .photos {
        width: 50%;
        margin-top: -200px;
        margin-left: -30px;
    }

    .photos_first {
        top: 165px;
        left: 77px;
        width: 198px;
        height: 198px;

        &::before {
            width: 216px;
            height: 216px;
        }

        & > img {
            top: -140px;
            left: -50px;
            width: 267px;
            height: 355px;
        }
    }

    .photos_second {
        width: 194px;
        height: 194px;

        & > img {
            top: -103px;
            width: 256px;
            height: 306px;
        }
    }

    .description_caption {
        & span:first-child {
            margin-left: 0;
        }

        & span:last-child {
            font-size: 60px;
            line-height: 93px;
        }
    }

    .description {
        width: 44%;
        margin-left: 80px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .about {
        margin-bottom: 62px;
        padding: 6px 0 200px;
    }

    .container {
        flex-direction: column;
        width: var(--container-width-mobile);
        margin: 0 var(--container-mg-mobile);
    }

    .bg_texture {
        top: 50px;
        background: url('../../theme/assets/images/mobile/texture_back_secondary_mobile.png') no-repeat;
    }

    .photos {
        justify-content: center;
        width: max-content;
        margin-top: 130px;
    }

    .photos_first {
        top: 50px;
        left: 0;
        width: 135px;
        height: 135px;

        &::before {
            top: -6px;
            left: 8px;
            width: 147px;
            height: 147px;
        }

        & > img {
            top: -95px;
            left: -33px;
            width: 182px;
            height: 242px;
        }

        .first_logo_icon {
            bottom: -18px;
            right: -92px;
            width: 90px;
            height: 33px;
            background-size: contain;
        }
    }

    .photos_second {
        width: 131px;
        height: 131px;

        & > img {
            top: -70px;
            width: 173px;
            height: 207px;
        }
    }

    .description_caption {
        position: relative;
        top: -310px;
        left: 50px;

        & span:first-child {
            margin-top: 0;
            margin-left: -50px;
            font-size: 30px;
            line-height: 39px;
        }

        & span:last-child {
            font-size: var(--main-title-fsize);
            line-height: 66px;
        }
    }

    .description {
        width: 100%;
        margin-top: -50px;
        margin-left: 0;
    }

    .description_info_first,
    .description_info_second {
        font-size: var(--secondary-fsize);
        line-height: 19px;
    }

    .line_wrap {
        bottom: -100px;
    }

    .line_thin {
        height: 26px;
        font-size: var(--secondary-fsize);
        line-height: 26px;
    }
}
