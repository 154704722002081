.localization_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50px;
}

.dropdown {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
}

.dropdown_title {
    margin-right: 5px;
    font-family: Exo_Bold, sans-serif;
    font-size: var(--main-fsize);
    line-height: 24px;
    white-space: nowrap;
    color: var(--title-color);
}

.dropdown_arrow,
.dropdown_arrow_open {
    display: inline-block;
    width: 9px;
    height: 8px;
    background: url('../../theme/assets/icons/faq_arrow.svg') no-repeat;
    background-size: cover;
    transition: all .3s ease;
}

.dropdown_arrow_open {
    transform: rotate(180deg);
}

.dropdown_list,
.dropdown_list_open {
    position: absolute;
    top: 30px;
    left: -5px;
    width: fit-content;
    max-height: 0;
    padding: 0;
    border: none;
    border-radius: var(--menu-bradius);
    box-shadow: none;
    overflow: hidden;
    transition: all .3s ease;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: auto;
        padding: 10px 20px;
        border-radius: var(--menu-bradius);
        background: linear-gradient(45deg, rgba(85, 78, 94, 0.25) 0%, rgba(57, 48, 73, 0.25) 101.68%);
    }
}

.dropdown_list_open {
    max-height: fit-content;
    padding: 4px;
    border: 1px solid var(--title-color);
    box-shadow: var(--pink-bshadow);

    & .list_item:last-child {
        margin-bottom: 0;
    }
}

.list_item {
    display: inline-block;
    margin-bottom: 16px;
    font-family: Exo_Bold, sans-serif;
    font-size: var(--main-fsize);
    line-height: 24px;
    white-space: nowrap;
    color: var(--title-color);
    cursor: pointer;

    &:hover {
        color: var(--main-color)
    }
}

@media (max-width: 1024px) {
    .localization_wrapper {
        margin-left: -15px;
    }

    .dropdown_title {
        margin-right: 14px;
        font-size: var(--main-title-fsize-mobile);
        line-height: 39px;
    }

    .dropdown_arrow,
    .dropdown_arrow_open {
        width: 19px;
        height: 16px;
        margin-top: 5px;
        background: url('../../theme/assets/icons/faq_arrow.svg') no-repeat;
        background-size: contain;
    }

    .dropdown_list,
    .dropdown_list_open {
        position: absolute;
        top: 50px;
    }

    .list_item {
        font-size: var(--main-title-fsize-mobile);
        line-height: 39px;
    }
}

