.social_wall {
    position: relative;
    margin-bottom: 195px;
}

.bg_texture,
.bg_texture_left {
    position: absolute;
    top: -310px;
    right: 0;
    z-index: 0;
    width: 520px;
    height: 729px;
    background: url('../../theme/assets/images/texture_back_secondary.png') no-repeat;
    transform: rotate(180deg);
}

.bg_texture_left {
    top: 300px;
    left: 0;
    transform: rotate(0);
}

.container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    max-width: var(--container-width);
    margin: 0 auto;
}

.description {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 50px;
}

.description_caption {
    margin-bottom: 11px;
    font-family: Exo_Black, sans-serif;
    font-size: var(--main-title-fsize);
    line-height: 66px;
}

.description_info {
    width: 90%;
    font-size: var(--main-fsize);
    line-height: 25px;
}

.comments {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;

    & > div  button[type="button"] {
        display: none !important;

        &::before {
            display: none;
        }
    }
}

.comments_card {
    display: flex;
    flex-direction: column;
    width: 340px !important;
    margin: 0 30px !important;
    height: fit-content;
}

.card_caption {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .caption_avatar {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        border-radius: 50%;

        & > img {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

    .caption_text {
        display: flex;
        flex-direction: column;

        & span:first-child {
            display: inline-block;
            font-family: OpenSans_Bold, sans-serif;
            font-size: var(--secondary-fsize);
            line-height: 19px;
        }

        & span:last-child {
            display: inline-block;
            font-size: var(--secondary-fsize);
            line-height: 19px;
        }
    }
}

.card_img {
    width: 340px;
    height: 340px;
    margin-bottom: 20px;
    border-radius: var(--menu-bradius);

    & > img {
        display: inline-block;
        width: 340px;
        height: 340px;
        border-radius: var(--menu-bradius);
    }
}

.card_descr {
    width: 100%;
    font-size: var(--secondary-fsize);
    line-height: 19px;
}

@media (min-width: 1024px) and (max-width: 1333px) {
    .container {
        width: var(--container-width-ipad);
        margin: 0 var(--container-mg-ipad);
    }

    .description {
        width: 60%;
    }

    .comments {
        & div[aria-hidden] {
            display: flex !important;
            justify-content: center !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .bg_texture {
        right: -185px;
    }

    .bg_texture_left {
        left: -185px;
    }

    .container {
        width: var(--container-width-ipad);
        margin: 0 var(--container-mg-ipad);
    }

    .description {
        width: 90%;
    }

    .comments {
        & div[aria-hidden] {
            display: flex !important;
            justify-content: center !important;
        }
    }

    .comments_card {
        width: 300px !important;
        margin: 0 auto !important;
    }

    .card_img {
        width: 300px;
        height: 300px;

        & > img {
            width: 300px;
            height: 300px;
        }
    }
}

@media (max-width: 768px) {
    .social_wall {
        margin-bottom: 95px;
    }

    .bg_texture,
    .bg_texture_left {
        position: absolute;
        top: -260px;
        right: 0;
        z-index: 0;
        width: 263px;
        height: 370px;
        background: url('../../theme/assets/images/mobile/texture_back_secondary_mobile.png') no-repeat;
        transform: rotate(180deg);
    }

    .bg_texture_left {
        top: 380px;
        left: 0;
        transform: rotate(0);
    }

    .container {
        width: var(--container-width-mobile);
        margin: 0 var(--container-mg-mobile);
    }

    .description {
        width: 100%;
        margin-bottom: 35px;
    }

    .description_caption {
        font-size: 30px;
        line-height: 40px;
    }

    .description_info {
        width: 100%;
    }

    .comments {
        & div[aria-hidden] {
            display: flex !important;
            justify-content: center !important;
        }
    }

    .comments_card {
        width: 200px !important;
        margin: 0 auto !important;
    }

    .card_img {
        width: 200px;
        height: 200px;

        & > img {
            width: 200px;
            height: 200px;
        }
    }
}

